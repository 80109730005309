@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400&display=swap'); /* Importing a similar font like Merriweather */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');




/* Temp fo m*/

/* Responsive modal */
.modal {
    display: flex;
    flex-direction: column;
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 500px; /* Maximum width for larger screens */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    z-index: 15;
    margin-top: 10px; /* Adjust this value to push up the modal */
}


.modal {
    position: absolute;
    top: 56%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36%; /* Responsive width */
    max-width: 700px; /* Increased maximum width */
    height: auto; /* Allow height to adjust based on content */
    max-height: 88vh; /* Maximum height based on viewport */
    /*overflow-y: auto;*/ /* Enable scrolling if content exceeds max height */
    padding: 40px; /* Increased padding for more space */
    background-color: #F8F8F8;
    border-radius: 18px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3); /* Increased shadow effect */
    border: none;
    outline: none;
    margin-top: 10vh;
    margin-bottom: 10vh;
    z-index: 3;
}






/* Responsive adjustments */
@media (max-width: 768px) {
    .popup-container1 {
        width: 90%;
    }

    .modal {
        max-width: 90%;
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .modal {
        padding: 10px;
    }

    .input-fieldS {
        padding: 8px;
        margin: 8px 0;
    }

    .signup-btnS {
        padding: 8px;
        font-size: 14px;
    }
}

body {
    overflow-x: hidden; /* Disables horizontal scroll */
    overflow-y: auto; /* Enables vertical scroll */
}














.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(240, 240, 240, 0.9); /* Light gray background with slight transparency */
    display: flex;
    justify-content: center; /* Horizontally center the popup */
    align-items: center; /* Vertically center the popup */
    z-index: 1000;
}

/* Popup container */
.popup {
    background-color: white;
    padding: 40px;
    width: 800px; /* Width of the popup */
    height: auto;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Added shadow effect */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*transform: scale(4);*/ /* Zooms the popup to 120% */
    transition: transform 0.3s ease;
}

/* Close button */
.close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    color: black;
    background: none;
    border: none;
    cursor: pointer;
}

/* Container for login and register forms */
.form-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch; /* Stretch to ensure equal height of sections */
    gap: 40px; /* Increased gap between sections */
    width: 100%; /* Make container fill the popup */
}

/* Each form section */
.form-section {
    width: 121%; /* Adjust width for both forms */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Align forms vertically centered */
    align-items: flex-start;
}

/* Styling for form elements */
/*input[type="email"],
input[type="password"],
input[type="text"] {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

    button[type="submit"]:hover {
        background-color: #0056b3;
    }*/

/* Vertical divider */
.divider {
    width: 1px; /* Thickness of vertical line */
    background-color: #9f9999; /* Blue color for divider */
    height: auto; /* Automatic height adjustment */
    flex-shrink: 0; /* Ensure it doesn't shrink */
}




/* Title Styles */
.popup-title {
    font-family: 'Merriweather', Georgia, serif; 
    font-size: 24px; 
    text-align: left;
    margin-bottom: 6px;
}

.popup-title1 {
    font-family: 'Merriweather', Georgia, serif;  
    font-size: 14px;  
    text-align: left;  
    margin-bottom: 20px;
}

.popup-title3{
    font-family: 'Merriweather', Georgia, serif;  
    font-size: 14px;  
    text-align: center;  
    margin-bottom: 20px;
    width:50%;
}
.popup-title2 {
    font-family: 'Merriweather', Georgia, serif;  
    font-size: 11px;  
    color: #555555;  
    background-color: rgba(0, 0, 0, 0);  
    margin-top: 10px;
    text-align: left;  
}
.popup-titleS {
    font-family: 'Merriweather', Georgia, serif;
    font-size: 18px;
    color: #555555;
    background-color: rgba(0, 0, 0, 0);
    margin-top: 2px;
    text-align: center;
    padding: 26px;
}
.popup-titleN{
    font-family: 'Merriweather', Georgia, serif;
    font-size: 14px;
/*    text-align: left;
    margin-bottom: 20px;*/
}

.highlight-text {
    color: #159AFF;
    font-size: 16px;
    cursor: pointer;
}
    .highlight-text:hover {
        color: #0056b3; /* Change color on hover */
        text-decoration: none; /* Remove underline on hover */
    }
.input-field {
    font-family: 'Merriweather', Georgia;
    color: #666;
    font-size: 12px;
    text-align: left;
    border: none;
    outline: none;
}
.input-field:focus {
    /*outline: none;*/
    /*border-color: transparent;*/ /* If border color is contributing to the effect */
}


/* App Styles */
/*.App {
    font-family: Arial, sans-serif;
    text-align: center;
    margin-top: 100px;
}

.open-popup-btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}*/

/* Popup Overlay */
/*.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}*/

/* Popup Styles */
/*.popup {
    background-color: white;
    padding: 20px;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}*/

.popup-logo {
    width: 100px;
    margin-bottom: 20px;
    
}



.form-group {
    margin-bottom: 15px;
    text-align: left;
}

    .form-group label {
        display: block;
        margin-bottom: 5px;
    }

    .form-group input {
        width: 140%;
        padding: 8px;
        border-radius: 4px;
        background: #F8F8F8;
        /*border: 0.2px solid #ccc;*/
    }

.login-btn {
    width: 140%;
    padding: 10px;
    background-color: #159AFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.right-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/* Image in the right section */
.info-image {
    width: 230px; /* Adjust image size */
    margin-bottom: 20px;
}

/* Text below the image */
.info-text {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
}

/* Learn More button */
.learn-more-btn {
    padding: 10px 20px;
    background-color: #ECF7FE;
    color: #169FFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

    .learn-more-btn:hover {
        background-color: #ECF7FE;
    }

/*.close-popup-btn {
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}*/
@media (max-width: 800px) {
    .popup {
        padding: 15px; /* Less padding */
        margin-top:20%;
    }

    .form-container {
        flex-direction: column; /* Stack forms vertically */
        overflow-y: auto;
        margin-right:50%;

    }
    .overlay{
        overflow-y:auto;
        overflow-x:hidden;
        align-items:center;
        width:124%;
    }
    *,.overlay{
        /*overflow-x:hidden;*/
    }
    .form-section {
        align-items: center;
    }
    .LoginIcon {
        /*align-items: center;*/
        display: none; /* Hide divider on small screens */
      
    }


    .divider {
        display: none; /* Hide divider on small screens */
    }

    .popup-logo {
    
        margin-top: 20%;

    }
    .learn-more-btn {
        margin-bottom:20px;
    }
    }


.full-screen-background {
    position: relative;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    overflow: hidden; /* Prevent scrolling */
}

/* Make sure the image covers the full background */
.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*object-fit: cover;*/ /* Ensure the image covers the container */
    z-index: -1; /* Place the image behind other content */
}


/* Overlay for dimming background */
.overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-color: rgba(0, 0, 0, 0.5);*/ /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Popup container with small image above */
.popup-container1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Small image (above popup) */
.small-logo {
    width: 90%; /* Adjust the size of the small logo */
    margin-bottom: 500px; /* Space between logo and popup */
    z-index:1;
    /*margin-top:10px;*/
}

/* Small image (above popup) */
/*.big-text {*/
    /*width: 350%;*/ /* Adjust the size of the small logo */
    /*margin-bottom: 600%;*/ /* Space between logo and popup */
    /*z-index:1;
    font-size:50px;*/
    /*margin-top:10px;*/
/*}*/

/* Popup styling */
.popup1 {
    background-color: white;
    padding: 40px; /* Increased padding */
    width: 1400px; /* Increased width */
    max-width: 90%; /* Ensures responsiveness */
    height: 1000px; /* Increased height */
    max-height: 90%; /* Ensures responsiveness */
    border-radius: 10px;
    position: relative;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Increased shadow effect */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

    /* Popup heading */
    .popup h2 {
        margin-bottom: 20px;
        color: #333;
    }

/* Input fields */
.input-field1 {

    width: 500%;
    /*padding: 20px;*/
    /*margin: 10px 0;*/
    border: none;
    border-radius: 5px;
    background-color: #f0f0f0;
    justify-content:center;
}

/* Signup button */
.signup-btn {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

    .signup-btn:hover {
        background-color: #0056b3;
    }
.form-group1{
    margin-bottom: 15px;
    text-align: center;
    justify-content:center;
}




/* Input field styles */
.input-fieldS {
    margin: 8px 0;
    padding-bottom: 7px;
    padding-left:15px;
    width: 105%; /* Full width */
    box-sizing: border-box; /* Include padding in width */
    border: 1px solid #ccc; /* Add border */
    border-radius: 10px; /* Rounded corners */
    outline: none; /* Remove outline */
    font-size: 16px;
    height: auto;
    margin-right:10px;
    padding:12px;
    /*text-align: center;*/
}

/* Button styles */
.signup-btnS, .close-btnS {
    padding: 13px 10px;
    margin-top: 2px;
    height:auto;
    color: white; /* White text */
    border: none; /* No border */
    cursor: pointer; /* Pointer cursor */
    width: 105%; /* Full width */
    border-radius: 10px; /* Rounded corners */
    margin-bottom:-13px !important;
}

.signup-btnS {
    background-color: #0075FF; /* Green background */
    font-size: 18px;
    font-family: 'Merriweather', Aerial;
}

.close-btnS {
    background-color: #f44336; /* Red background */
}

/* Button hover effects */
.signup-btnS:hover {
    background-color: #45a049; /* Darker green on hover */
}

.close-btnS:hover {
    background-color: #d32f2f; /* Darker red on hover */
}

/* Media Queries for responsiveness */
/*@media (max-width: 600px) {
    .modal {
        width: 95%;*/ /* Adjust width for smaller screens */
        /*padding: 40px;*/ /* Reduce padding */
    /*}

    .input-fieldS {
        padding: 8px;*/ /* Adjust input padding */
    /*}

    .signup-btnS, .close-btnS {
        padding: 8px;*/ /* Adjust button padding */
    /*}
}*/

.input-fieldS::placeholder {
    font-size: 16px; /* Match this with the input font size */
    font-family: 'Merriweather', sans-serif;

 
    /*color: #999;*/ /* Optional: change color for better visibility */
}

.custom-div {
    font-family: 'Arial', sans-serif; /* Replace with your desired font */
    font-size: 18px;
    color: #848484;
    color: RGB(132, 132, 132);
    background-color: #000000;
    background-color: RGBA(0, 0, 0, 0);
    padding:12px;
}
.custom-div1 {
    font-family: 'Arial', sans-serif; /* Replace with your desired font */
    font-size: 16px;
    color: black;
    color: black;
    background-color: #000000;
    background-color: RGBA(0, 0, 0, 0);
    padding:8px;
    margin-bottom:5px;
}


.highlight{
    color: #159AFF;
    /*font-size: 16px;*/
    cursor: pointer;
}

    .highlight:hover {
        color: #0056b3; /* Change color on hover */
        text-decoration: none; /* Remove underline on hover */
        font-weight: bold; /* Makes text bold */
        /*text-decoration: underline;*/
    }
/* Add some styles to ensure visibility */
input[type="checkbox"] {
    width: 18px; /* Set a specific width */
    height: 18px; /* Set a specific height */
    margin-right:10px;
}


@media (max-width: 982px) {
    .modal {
        width: 50%;
        /*max-height: 100vh;*/
    }
}

@media (max-width: 510px) {
    .modal{
        width:89%;
        max-height:100vh;
    }
    .small-logo {
        width: 70%;
    }
    .input-fieldS::placeholder {
        font-size: 15px; /* Match this with the input font size */
    }
    /*.popup-container1 {
        padding: 10px;
    }*/

    .input-fieldS {
        padding: 15px;
    }

    /*.signup-btnS {
        padding: 8px;
        font-size: 14px;*/ /* Adjust button font size */
    /*}*/
}

.custom-submenu {
    color: #ffffff;
    background-color: #343a40;
}

.custom-menu-item {
    color: #ffffff;
    background-color: #495057;
}

    .custom-menu-item:hover, .custom-submenu:hover {
        background-color: #6c757d;
        cursor: pointer;
    }

/* Sidebar styling */
.sidebar-container {
    height: 100vh; /* Full height of the viewport */
    display: flex;
}

.custom-sidebar {
    background-color: #212529;
    width: 200px; /* Increased width */
}

/* Adjust the sidebar for responsiveness */
@media (max-width: 768px) {
    .custom-sidebar {
        width: 200px; /* Smaller width for mobile */
    }
}

/* Menu item icon styling */
.menu-item-icon {
    margin-right: 10px;
}

    /* Custom icon size */
    .menu-item-icon svg {
        font-size: 9px;
    }
/*

* {
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

body {
    background: #f5f6fa;
}

.wrapper .sidebar {
    background: rgb(5, 68, 104);
    position: fixed;
    top: 0;
    left: 0;
    width: 617px;
    height: 100%;
    padding: 20px 0;
    transition: all 0.5s ease;
}

    .wrapper .sidebar ul li a {
        display: block;
        padding: 13px 30px;
        border-bottom: 1px solid #10558d;
        color: rgb(241, 237, 237);
        font-size: 16px;
        position: relative;
    }

        .wrapper .sidebar ul li a .icon {
            color: #dee4ec;
            width: 30px;
            display: inline-block;
        }

        .wrapper .sidebar ul li a:hover,
        .wrapper .sidebar ul li a.active {
            color: #0c7db1;
            background: white;
            border-right: 2px solid rgb(5, 68, 104);
        }

            .wrapper .sidebar ul li a:hover .icon,
            .wrapper .sidebar ul li a.active .icon {
                color: #0c7db1;
            }

            .wrapper .sidebar ul li a:hover:before,
            .wrapper .sidebar ul li a.active:before {
                display: block;
            }*/

/* Sidebar.css */

* {
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

body {
    background: #f5f6fa;
    transition: margin-left 0.5s;
}


.wrapper .sidebar {
    background: #21263C; /*/*#181C2E rgb(5, 68, 104);*/
    position: fixed;
    top: 0;
    left: 0;
    width: 12rem;
    height: 100%;
    padding: 15px 0;
    transition: all 0.5s ease;
    overflow-y: auto;
}

.sidebar {
    z-index: 1;
    transition: width 0.3s ease;
    width: 12rem;
    position: relative;
}

    .sidebar.collapsed {
        width: 4rem; /* Adjust as needed */
    }

.profile img {
    transition: opacity 0.3s ease;
}

.profile h3, .profile p {
    transition: opacity 0.3s ease;
}

/* Optionally hide profile details when collapsed */
.sidebar.collapsed .profile img,
.sidebar.collapsed .profile h3,
.sidebar.collapsed .profile p {
    opacity: 0;
    visibility: hidden;
}

.sidebar.collapsed .icon {
    font-size: 22px !important; /* Adjust to your preference */
    margin-right: 12px;
}

    .wrapper .sidebar .profile {
        margin-bottom: 1rem;
        text-align: center;
    }

        .wrapper .sidebar .profile img {
            display: block;
            width: 8rem;
            height: 8rem;
            border-radius: 50%;
            margin: 0 auto;
        }


        .wrapper .sidebar .profile h3 {
            color: #ffffff;
            margin: 15px 0 3px; /* Increased margins */
            font-size: 0.7rem; /* Increased font size */
        }

        .wrapper .sidebar .profile p {
            color: rgb(206, 240, 253);
            font-size: 0.9rem; /* Increased font size */
        }

    .wrapper .sidebar ul li a {
        display: block;
        padding: 10px 23px; /* Increased padding */
        border-bottom: 2px solid #10558d; /* Increased border size */
        color: rgb(241, 237, 237);
        font-size: 25px; /* Increased font size */
        position: relative;
        white-space: nowrap;
        overflow: hidden; /* or scroll */
        /*text-overflow: ellipsis;*/
    }

        .wrapper .sidebar ul li a .icon {
            color: #dee4ec;
            width: 25px; /* Increased icon width */
            display: inline-block;
            font-size: 15px; /* Increased icon size */
        }

        .wrapper .sidebar ul li a:hover,
        .wrapper .sidebar ul li a.active {
            color: #0c7db1;
            background: #181C2E;
            border-right: 3px solid rgb(5, 68, 104); /* Increased border size */
        }
.item {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden; /* or scroll */
    text-overflow: ellipsis;
}

.static-icon {
    position: fixed; /* Fixes the icon position */
    bottom: 10px; /* Distance from the bottom */
    left: 20px; /* Distance from the left, adjust as needed */
    z-index: 0; /* Ensures it's on top */
    color:white;
}
.wrapper .section {
    width: 1366px; /* Adjusted width */
    margin-left: 0px; /* Adjusted margin */
    transition: all 0.5s ease;
}

    .wrapper .section .top_navbar {
        background: #FCFFEC;
        height: 2.5rem; /* Increased height */
        display: flex;
        align-items: center;
        padding: 0 38px; /* Increased padding */
    }
    .top_navbar{
        position:fixed;
    }

        .wrapper .section .top_navbar .hamburger a {
            font-size: 1em !important; /* Increased font size */
            color: #f4fbff;
        }

.FaAngleDown {
    /*margin-left: 190px !important;*/
    margin-left: clamp(190px, 80vw + 190px, 897px) !important;
}

.FaBars {
    /*margin-left: 35px !important;*/
    margin-left: clamp(35px, 80vw + 35px, 169px) !important;
}
/*Standard width form media query*/
/* Small Mobile */
@media (max-width: 320px) {
    /* Styles for small mobile devices */
}

/* Mobile */
@media (max-width: 480px) {
    /* Styles for mobile devices */
}
/* Mobile */
@media (max-width: 505px) {
    /* Styles for mobile devices */
    .wrapper .section .top_navbar {
        height: 2rem; /* Increased height */
        width:100%;
    
        /*padding: 0 30% !important;*/ /* Increased padding */
    }
    .FaAngleDown {
        margin-left: 190px !important;
    }
    .FaBars {
        margin-left: 35px !important;
    }
    .RightSideIcon{
        margin-left:30px !important;
    }
    .wrapper .section .top_navbar .hamburger a {
        font-size: 0.8em !important; /* Increased font size */
    }

}

@media (min-width: 1362px) {

    .wrapper .section .top_navbar {
        /*height: 2rem;*/ /* Increased height */
        /*width: 100%;*/
        /*padding: 0 30% !important;*/ /* Increased padding */
    }

    .FaAngleDown {
        margin-left: 897px !important;
    }

    .FaBars {
        margin-left: 169px !important;
    }

    .RightSideIcon {
        margin-left: 30px !important;
    }

    .wrapper .section .top_navbar .hamburger a {
        /*font-size: 0.8em !important;*/ /* Increased font size */
    }
}


/* Small Tablet */
@media (max-width: 600px) {
    /* Styles for small tablets */
}

/* Tablet */
@media (max-width: 768px) {


    }

/* Small Laptop */
@media (max-width: 1024px) {

}

/* Desktop */
@media (max-width: 1280px) {

}

/* Large Desktop */
@media (max-width: 1440px) {

}

/* Extra Large Desktop */
@media (max-width: 1920px) {

}






@media screen and (max-width: 1366px) {
    .wrapper .section .top_navbar .hamburger a {
        font-size: 1.3em; /* Increased font size */

    }

}

/* Media query for screens smaller than 480px */
@media screen and (max-width: 768px) {
    .wrapper .section .top_navbar .hamburger a {
        font-size: 0.3em; /* Increased font size */
    }
}
@media screen and (max-width: 800px) {
    .sidebar.collapsed {
        width: 4rem; /* Adjust as needed */
    }
}


body.active .wrapper .sidebar {
    left: -305px; /* Adjusted for new sidebar width */
}

body.active .wrapper .section {
    margin-left: 0;
    width: 20%;
}



    .wrapper .sidebar ul {
        padding: 0;
        margin: 0;
    }

    /* Optional: style the scrollbar (for Webkit browsers) */
    .wrapper .sidebar::-webkit-scrollbar {
        width: 5px; /* Width of the scrollbar */
    }

    .wrapper .sidebar::-webkit-scrollbar-thumb {
        background: #159AFF; /* Color of the scrollbar thumb */
        border-radius: 1px; /* Round the corners of the scrollbar thumb */
    }

        .wrapper .sidebar::-webkit-scrollbar-thumb:hover {
            background: #aaa; /* Color when hovering over the scrollbar thumb */
        }


/*social media icons*/

.wrapperL {
    display: inline-flex;
    list-style: none;
}

    .wrapperL .icon {
        position: relative;
        background: #ffffff;
        border-radius: 50%;
        padding: 15px;
        margin: 10px;
        width: 2px;
        height: 2px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .wrapperL .tooltip {
        position: absolute;
        top: 0;
        font-size: 14px;
        background: #ffffff;
        color: #ffffff;
        padding: 5px 8px;
        border-radius: 5px;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

        .wrapperL .tooltip::before {
            position: absolute;
            content: "";
            height: 8px;
            width: 8px;
            background: #ffffff;
            bottom: -3px;
            left: 50%;
            transform: translate(-50%) rotate(45deg);
            transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }

    .wrapperL .icon:hover .tooltip {
        top: -45px;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }

    .wrapperL .icon:hover span,
    .wrapperL .icon:hover .tooltip {
        text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    }

    .wrapperL .facebook:hover,
    .wrapperL .facebook:hover .tooltip,
    .wrapperL .facebook:hover .tooltip::before {
        background: #1877F2;
        color: #ffffff;
    }

    .wrapperL .twitter:hover,
    .wrapperL .twitter:hover .tooltip,
    .wrapperL .twitter:hover .tooltip::before {
        background: #1DA1F2;
        color: #ffffff;
    }

    .wrapperL .instagram:hover,
    .wrapperL .instagram:hover .tooltip,
    .wrapperL .instagram:hover .tooltip::before {
        background: #E4405F;
        color: #ffffff;
    }

    .wrapperL .github:hover,
    .wrapperL .github:hover .tooltip,
    .wrapperL .github:hover .tooltip::before {
        background: #333333;
        color: #ffffff;
    }

    .wrapperL .youtube:hover,
    .wrapperL .youtube:hover .tooltip,
    .wrapperL .youtube:hover .tooltip::before {
        background: #CD201F;
        color: #ffffff;
    }

    .wrapperL .gmail:hover,
    .wrapperL .gmail:hover .tooltip,
    .wrapperL .gmail:hover .tooltip::before {
        background: #CD201F;
        color: #ffffff;
    }

.image-shadow {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* Customize the shadow properties here */
}





.wrapperS {
    display: inline-flex;
    list-style: none;
}

    .wrapperS .icon {
        position: relative;
        background: #ffffff;
        border-radius: 50%;
        padding: 21px;
        margin: -15px;
        width: 136px;
        height: 110px;
        font-size: 59px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        margin-left: 20%;
        margin-top:10%;
    }

    .wrapperS .tooltip {
        position: absolute;
        top: 0;
        font-size: 37px;
        background: #ffffff;
        color: #ffffff;
        padding: 18px 21px;
        border-radius: 10px;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

        .wrapperS .tooltip::before {
            position: absolute;
            content: "";
            height: 8px;
            width: 8px;
            background: #ffffff;
            bottom: -3px;
            left: 50%;
            transform: translate(-50%) rotate(45deg);
            transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }

    .wrapperS .icon:hover .tooltip {
        top: -120px;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }

    .wrapperS .icon:hover span,
    .wrapperS .icon:hover .tooltip {
        text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    }

    .wrapperS .facebook:hover,
    .wrapperS .facebook:hover .tooltip,
    .wrapperS .facebook:hover .tooltip::before {
        background: #1877F2;
        color: #ffffff;
    }

    .wrapperS .twitter:hover,
    .wrapperS .twitter:hover .tooltip,
    .wrapperS .twitter:hover .tooltip::before {
        background: #1DA1F2;
        color: #ffffff;
    }

    .wrapperS .instagram:hover,
    .wrapperS .instagram:hover .tooltip,
    .wrapperS .instagram:hover .tooltip::before {
        background: #E4405F;
        color: #ffffff;
    }

    .wrapperS .github:hover,
    .wrapperS .github:hover .tooltip,
    .wrapperS .github:hover .tooltip::before {
        background: #333333;
        color: #ffffff;
    }

    .wrapperS .youtube:hover,
    .wrapperS .youtube:hover .tooltip,
    .wrapperS .youtube:hover .tooltip::before {
        background: #CD201F;
        color: #ffffff;
    }

    .wrapperS .gmail:hover,
    .wrapperS .gmail:hover .tooltip,
    .wrapperS .gmail:hover .tooltip::before {
        background: #CD201F;
        color: #ffffff;
    }

.image-shadow {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* Customize the shadow properties here */
}


/*Alert box*/
.alert-box {
    position: fixed; /* Use fixed positioning */
    top: 20px; /* Distance from the top */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust to perfectly center */
    z-index: 1000; /* Ensure it's on top of other elements */
    padding: 7px 20px; /* Padding for the box */
    border-radius: 5px; /* Rounded corners */
    color: white; /* Text color */
    display: flex; /* Flex for alignment */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Space between text and button */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Shadow for depth */
    width: auto; /* Allow it to grow with the message */
    max-width: 90%; /* Maximum width to prevent overflow */
    transition: all 0.3s ease;
}

.alert-message {
    flex-grow: 1; /* Allow message to take available space */
    margin-right: 15px; /* Space between message and button */
    font-size: 14px; /* Font size for readability */
    font-family: 'Merriweather', Georgia, serif;
}

.alert-box.success {
    background-color: #28a745; /* Green */
}

.alert-box.error {
    background-color: #dc3545; /* Red */
}

.alert-box.warning {
    background-color: #ffc107; /* Yellow */
    color: black; /* Text color for warning */
}

.close-button {
    background: none; /* Remove background */
    border: none; /* Remove border */
    color: white; /* Button text color */
    font-size: 20px; /* Larger font for visibility */
    cursor: pointer; /* Pointer cursor on hover */
    transition: color 0.3s ease; /* Transition effect for hover */
}

    .close-button:hover {
        color: #ffcc00; /* Change color on hover */
    }

    /* Main menu css*/
        
    /* Organisation page*/

.rounded-input {
    background-color: #fcfcfc; /* Slightly different from white */
    border-radius: 10px; /* Rounded corners */
    border: 1px solid #ced4da;
    padding: 12px;
    padding-bottom:3px;
    color: #495057; /* Text color */
}

.input-row {
    margin-bottom: 20px; /* Adjust this value to control the space between inputs */
}

.rounded-box {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 15px;
    background-color: #f8f9fa; /* Light gray background */
    display: inline-block; /* Ensures that the box wraps just around the content */
}

.rounded-id-box {
    margin-left : 10px;
    border-radius: 50px; /* Full round edges */
    padding: 5px 15px; /* Adjust padding for spacing */
    background-color: #f3f3fb; /* Light purple-gray background */
    display: inline-flex; /* Flexbox for centering */
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    font-size: 14px;
    color: #333; /* Text color */
    font-weight: 500; /* Slightly bold */
    /*border: 1px solid #ccc;*/ /* Optional border for better visibility */
    height: 40px; /* Adjust the height to ensure proper vertical alignment */
}

.input-spacing {
    margin-left: 1px; /* Adjust as necessary */
}

/*image Upload*/
.container {
    background-color: #ffffff; /* Background color */
    border-radius: 5px; /* Rounded corners */
    padding: 20px; /* Spacing around content */
}

.upload-description-container {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
    margin-top: 10px;
}

.upload-box {
    border: 2px dashed #ccc;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    width: 50%; /* Adjust width as needed */
}

.upload-label {
    color: #007bff;
    font-weight: bold;
    font-size: 16px;
    display: block; /* Make label block to center icon */
}

.upload-icon {
    font-size: 24px; /* Adjust icon size as needed */
}

.description {
    margin-left: 20px; /* Space between upload box and description */
    width: 50%; /* Adjust width as needed */
}

.info-text {
    margin-top: 10px;
    color: #6c757d;
}



/* Loader design */
/* Loader.css */

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    width: 100%; /* Full width */
    position: fixed; /* Fixed position */
    top: 0;
    left: 0;
    z-index: 9999; /* Ensure it is above other content */
    background-color: rgba(255, 255, 255, 0.8); /* Light background for contrast */
}

.loader {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center text below spinner */
}

.spinner {
    width: 3rem;
    height: 3rem;
    border: 8px solid #f3f3f3; /* Light gray background */
    border-top: 8px solid #3498db; /* Blue color */
    border-radius: 50%; /* Circle shape */
    animation: spin 1s linear infinite; /* Animation for spinning */
}

.loading-text {
    margin-top: 10px; /* Space between spinner and text */
    font-size: 1.2rem; /* Increase font size */
    color: #3498db; /* Text color matching the spinner */
}

/* Spin Animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}





